import { ruleGuides } from "@/libs-core/guides.min.js";
import { selector } from "@/libs-core/selector.min.js";
import InfiniteViewer from "infinite-viewer";



export const viewerEditor = {
    frameMap: "",
    selectstart: "",
    init: function (thisVue) {
        var $this = this;
        thisVue.frameMap = new Map();
        thisVue.targets = [];
        const viewer = document.querySelector(".infinite-viewer");
        if (thisVue.infiniteViewer && thisVue.infiniteViewer.containerElement) {
            thisVue.infiniteViewer.destroy();
        }
        thisVue.infiniteViewer = new InfiniteViewer(
            document.querySelector(".container"),
            document.querySelector(".main-builder"),
            {
                margin: 0,
                threshold: 0,
                zoom: 1,
                rangeX: [0, 0],
                rangeY: [0, 0],
                useForceWheel: true,
                usePinch: true,
            },
        );
        //event  viewer
        thisVue.infiniteViewer.on("pinch", e => {
        });
        ruleGuides.init(thisVue);
        selector.init(thisVue);
    },
}